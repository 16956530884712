@import "../../../../assets/style/mixins.less";

.sub-container {
    margin-top: 20px;

    .filter-input{
        background-color: white;
        border: rgba(0, 0, 0, 0.05) solid 1px;
        width: 100%;
    }

    .filter-title{
        text-align: right;
    }

    .filter-select {
        width: 100%;
        border-radius: 5px;

        :global(.ant-select-selector) {
            border: rgba(0, 0, 0, 0.2) solid 1px !important;
            background-color: white !important;
        }
    }

    .table {
        margin-top: 20px;
        border: rgba(0, 0, 0, 0.2) solid 1px !important;
        border-radius: 5px;
    }

    .table-button {
        border: rgba(0, 0, 0, 0) solid 0px !important;
        margin-right: 15px
    }

    /* table */
    :global(.ant-table-cell) {
        // font-size: 14px;
        padding: 10px 10px !important;
    }

    .warning-input {
        border : @red2 solid 1px !important;
    }

    // :global(.ant-table-body::-webkit-scrollbar) {
    //     display: none;
    //   }
}