@import '../../../../../assets/style/mixins.less';

.modal-container {
    margin-top: 120px;

    .row-input {
        margin-top: 20px;
        // align-items: center;
        .filter-input {
            background-color: white;
            border: rgba(0, 0, 0, 0.2) solid 1px !important;
            width: 85%;
        }
        .filter-select {
            width: 85%;
            border-radius: 5px;
            :global(.ant-select-selector) {
                border: rgba(0, 0, 0, 0.2) solid 1px !important;
                background-color: white !important;
                color: @black !important;
            }
        }
        .small-input {
            background-color: white;
            border: rgba(0, 0, 0, 0.2) solid 1px !important;
            width: 42%;
        }
        .warning-input {
            border: @red2 solid 1px !important;
        }
    }

    .table {
        margin-top: 20px;
        border: rgba(0, 0, 0, 0.2) solid 1px !important;
        border-radius: 5px;
    }

    /* table */
    :global(.ant-table-cell) {
        // font-size: 14px;
        padding: 10px 10px !important;
    }
}
