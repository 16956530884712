@import '../../../../../assets/style/mixins.less';

.main {
    background-color: #f2f2f2;
    border: rgba(0, 0, 0, 0.05) solid 1px;
    border-radius: 6px;
    padding: 10px;

    .row-input {
        margin-top: 20px;
        // align-items: center;
        .filter-input {
            background-color: white;
            border: rgba(0, 0, 0, 0.2) solid 1px !important;
            width: 85%;
        }
        .filter-select {
            width: 85%;
            border-radius: 5px;
            :global(.ant-select-selector) {
                border: rgba(0, 0, 0, 0.2) solid 1px !important;
                background-color: white !important;
                color: @black !important;
            }
        }
        .small-input {
            background-color: white;
            border: rgba(0, 0, 0, 0.2) solid 1px !important;
            width: 42%;
        }
        .warning-input {
            border: @red2 solid 1px !important;
        }
    }

    .table {
        margin-top: 15px;
        border: rgba(0, 0, 0, 0.2) solid 1px !important;
        border-radius: 5px;
    }
    .table-button {
        border: rgba(0, 0, 0, 0) solid 0px !important;
        margin-right: 15px;
    }
    .table-input {
        background-color: white;
        border: rgba(0, 0, 0, 0.2) solid 1px !important;
        width: 100%;
    }
    .table-select {
        width: 100%;
        border-radius: 5px;
        :global(.ant-select-selector) {
            border: rgba(0, 0, 0, 0.2) solid 1px !important;
            background-color: white !important;
            color: @black !important;
        }
    }
    .table-note {
        background-color: white;
        border: rgba(0, 0, 0, 0.2) solid 1px !important;
        width: 100%;
        height: 100px;
    }

    .filter-title {
        text-align: left;
    }

    .filter-select {
        width: 100%;
        border-radius: 5px;

        :global(.ant-select-selector) {
            border: rgba(0, 0, 0, 0.2) solid 1px !important;
            background-color: white !important;
        }
    }

    .warning-input {
        border: @red2 solid 1px !important;
    }
    .warning-select {
        width: 100%;
        border-radius: 5px;
        :global(.ant-select-selector) {
            border: @red2 solid 1px !important;
            background-color: white !important;
            color: @black !important;
        }
    }

    /* table */
    :global(.ant-table-cell) {
        // font-size: 14px;
        padding: 10px 10px !important;
    }

    .filter-block {
        border: #2792ce solid 2px !important;
        padding: 10px 10px;
        border-radius: 4px;
        padding-left: 18px;
        margin-bottom: 30px;
        background-color: white;
        margin-top: 5px;
    }

    .filter-block-title {
        background-color: #f2f2f2;
        width: 140px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .add-button {
        background-color: @green2 !important;
        width: auto;
    }
    .delete-button {
        background-color: @red2 !important;
        width: auto;
    }
    .block-title {
        text-align: left;
        color: @navy1;
        font-size: 16px;
    }
    .block-input {
        background-color: white;
        border: @navy1 solid 1px;
        width: 85%;
        scroll-margin-block: 50px;
    }
}
