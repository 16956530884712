@import "./colors.less";

@header-height: 64px;

.main-container {
    padding: 20px;
    height: calc(100vh - 64px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.flex-container {
}

.filter-select {
    width: 100%;
    border-radius: 5px;

    :global(.ant-select-selector) {
        border: rgba(0, 0, 0, 0.2) solid 1px !important;
        background-color: white !important;
    }
}

.filter-button{
    background-color: @navy1 !important;
    width: 100%;
    color: @white !important;
    display: flex;
    align-items: center;
    justify-content: center;
    
}


.filter-button:hover{
    background-color: @navy2 !important;
    
}

.table-config-icon{
    background-color: @navy2 !important;
    margin-right: '10px';
    margin-left: '10px';
    font-size : '20px';
}

.ghost-button {
    border-color: @navy1 !important;
    color: @navy1 !important;
}
